import React, { Component } from "react";

function style(color) {
  return {
    "--color-hover": color,
  };
}

export function Footer(props) {
  const items = props.items;
  const itemsList = items.map((item) => (
    <li className="contacts__soc-net__item" key={item.id.toString()}>
      <a
        href={item.url}
        title={"" + item.title}
        alt={"" + item.alt}
        target="_blank"
        style={style(item.color)}
      >
        <i className={"fa-brands fa-" + item.icon}></i>
      </a>
    </li>
  ));

  return (
    <footer className="footer">
      <div id={props.id} className="contacts wrapper padding-bottom-32">
        <div className="contacts_row contacts__about-us">
          <h3 className="title">About Us</h3>
          <span className="text">
            Promoting the protection of human rights and civil liberties, as
            well as actions to support the development of democracy.
          </span>
        </div>
        <div className="contacts_row contacts__contacts">
          <a className="contact">
            <i className="fa-solid fa-house"></i>
            <span className="text">
              <strong>Address:</strong> Instalatorow 3, Rzeszów, Poland, 35-222
            </span>
          </a>
          <a href="tel:+48732941621" className="contact">
            <i className="fa-solid fa-phone"></i>
            <span className="text">
              <strong>Phone:</strong> +48 732 941 621
            </span>
          </a>
          <a href="maito:volodymyrdyba404@gmail.com" className="contact">
            <i className="fa-solid fa-envelope"></i>
            <span className="text">
              <strong>Email:</strong> volodymyrdyba404@gmail.com
            </span>
          </a>
        </div>
        <ul className="contacts_row contacts__soc-net">{itemsList}</ul>
      </div>
      <div className="footer__copyright">
        <span className="copyright">
          Copyright @ 2022 All Rights Reserved by
          <a href="https://ituse.org" target="_black">
            {" "}
            ITuse
          </a>
        </span>
      </div>
    </footer>
  );
}
