import React, { Component } from "react";

import joinus from "../joinus.png";
import weneed from "../weneed.png";

export function JoinUs(props) {
  return (
    <div id={props.id} className="join-us wrapper full-bleed">
      <div className="join-us__image">
        <img src={joinus} alt="join-us" />
      </div>

      <div className="join-us__item">
        <span className="subtitle">Join Us</span>
        <h3 className="title">Become a volunteer for save people</h3>
        <p className="text">
          Providing all kinds of assistance to people affected by the
          consequences of the humanitarian crisis, caused by natural disasters,
          natural disasters and war.
        </p>
        <a href="tel:+48732941621" className="action">
          Apply Here
        </a>
      </div>
      <div className="join-us__item">
        <span className="subtitle">We need</span>
        <h3 className="title">Donate humanitarian goods to help people</h3>
        <p className="text">
          Support of refugees, migrants, resettled persons and excluded persons,
          discrimination based on race, gender, nationality, beliefs, ethnic
          origin in the field of legal protection, humanitarian aid,
          professional activation, educational support and other needs, in the
          territory of the Republic of Poland and beyond its borders.
        </p>
        <a
          href="https://drive.google.com/file/d/1mg8M8SUSxCnIJ_CsftcwA--jVSy6PN-U/view?usp=share_link"
         target="_blank"
          className="action"
        >
          See the list here
        </a>
      </div>
      <div className="join-us__image">
        <img src={weneed} alt="we need" />
      </div>
    </div>
  );
}
