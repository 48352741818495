import React, { Component } from "react";

import * as com from "./components";
// SVG
import logo_icon_banner from "./logo_icon_banner.svg";

const itemsSoc = [
  {
    id: 1,
    url: "https://www.youtube.com/channel/UC6j0iinMF2KWokSxTvFqU9Q/",
    icon: "youtube",
    color: "#ff0000",
    title: "",
    alt: "",
  },
  {
    id: 2,
    url: "https://www.tiktok.com/@welcome_rzeszow",
    icon: "tiktok",
    color: "#000000",
    title: "",
    alt: "",
  },
  {
    id: 3,
    url: "https://www.facebook.com/WelcomeRzeszow",
    icon: "facebook",
    color: "#0a80ec",
    title: "",
    alt: "",
  },
  {
    id: 4,
    url: "https://www.tiktok.com/@volodymyr.dyba",
    icon: "tiktok",
    color: "#000000",
    title: "",
    alt: "",
  },
];

function App() {
  return (
    <div>
      <com.Header banner={logo_icon_banner} />
      <com.Main />
      <com.SocNet items={itemsSoc} />
      <com.AboutUs id="aboutus" />
      <com.JoinUs id="joinus" />
      <com.OutWork id="outwork" />
      <com.Charity id="chatiry" />
      <com.Galery />
      <com.Footer id="contacts" items={itemsSoc} />
    </div>
  );
}

export default App;

// Main,
// AboutUs,
// JoinUs,
// OutWork,
// Charity,
// Footer,
