import React, { Component } from "react";

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
  }

  handleOn = (e) => {
    this.setState((prevState) => ({
      isToggleOn: false,
    }));
    this.forceUpdate();
  };

  handleOff = (e) => {
    this.setState((prevState) => ({
      isToggleOn: true,
    }));
    this.forceUpdate();
  };
  render() {
    let _class = "mobile ";
    _class += this.state.isToggleOn ? "hide" : "show";
    return (
      <header className="header">
        <div className="wrapper nav padding-top-0">
          <div className="nav__burger" onClick={this.handleOn}>
            <i className="fa-solid fa-bars"></i>
          </div>

          <div className={_class}>
            <button className="close" onClick={this.handleOff}>
              <i class="fa-solid fa-xmark"></i>
            </button>
            <img
              src={this.props.banner}
              alt="Logotype Welcomer Reszow"
              title="Logotype Welcomer Reszow"
              className="nav__brand"
            />
            <br />
            <nav>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#aboutus">About</a>
                </li>
                <li>
                  <a href="#joinus">Join</a>
                </li>
                <li>
                  <a href="#outwork">Out Work</a>
                </li>
                <li>
                  <a href="#chatiry">Charity</a>
                </li>
                <li>
                  <a href="#contacts">Contacts</a>
                </li>
              </ul>
            </nav>
            <br />
            <ul className="lang">
              <li className="lang-item active">EN</li>
              <li className="lang-item">PL</li>
              <li className="lang-item">UA</li>
            </ul>
            <br />
            <button className="action">
              <a href="tel:+48732941621">Call Me</a>
            </button>
          </div>

          <img
            src={this.props.banner}
            alt="Logotype Welcomer Reszow"
            title="Logotype Welcomer Reszow"
            className="nav__brand"
          />

          <nav className="nav__menu">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#aboutus">About</a>
              </li>
              <li>
                <a href="#joinus">Join</a>
              </li>
              <li>
                <a href="#outwork">Out Work</a>
              </li>
              <li>
                <a href="#chatiry">Charity</a>
              </li>
              <li>
                <a href="#contacts">Contacts</a>
              </li>
            </ul>
          </nav>

          <ul className="nav__lang">
            <li className="lang-item active">EN</li>
            <li className="lang-item">PL</li>
            <li className="lang-item">UA</li>
          </ul>

          <button className="nav__call-action">
            <a href="tel:+48732941621">Call Me</a>
          </button>
        </div>
      </header>
    );
  }
}
