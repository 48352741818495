import React, { Component } from "react";

function style(color) {
  return {
    "--color-hover": color,
  };
}

export function SocNet(props) {
  const items = props.items;
  const itemsList = items.map((item) => (
    <li className="soc-net__item" key={item.id.toString()}>
      <a
        href={item.url}
        title={"" + item.title}
        alt={"" + item.alt}
        target="_blank"
        style={style(item.color)}
      >
        <i className={"fa-brands fa-" + item.icon}></i>
      </a>
    </li>
  ));

  return <ul className="soc-net">{itemsList}</ul>;
}
