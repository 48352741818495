import React, { Component } from "react";

import bay from "../bay.png";
import drone from "../drone.png";
import transport2 from "../transport2.png";

export function OutWork(props) {
  return (
    <div id={props.id} className="out-work">
      <div className="wrapper">
        <h3 className="out-work__title">Our works</h3>

        <div className="out-work__col">
          <a href="https://hockeybayraktar.com/#poland" target="_blank" className="out-work__card">
            <img src={bay} alt="Bayraktar hokejowy" className="image" />
            <span className="title">Bayraktar hokejowy</span>
            <span className="text">дата: 10-13 11.2022</span>
          </a>
          <a href="https://www.tiktok.com/@volodymyr.d" target="_blank" className="out-work__card">
            <img src={drone} alt="drone" className="image" />
            <span className="title">Drone</span>
            <span className="text">дата: 08.11.2022</span>
          </a>
          <a href="https://www.tiktok.com/@volodymyr.d/video/7114993318399872262" target="_blank" className="out-work__card">
            <img src={transport2} alt="transport" className="image" />
            <span className="title">Transport</span>
            <span className="text">дата: 2022</span>
          </a>
        </div>

        <div className="out-work__nav">
          
        </div>
      </div>
    </div>
  );
}
