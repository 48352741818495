import React, { Component } from "react";

import g1 from "../galery/1.jpg";
import g2 from "../galery/2.jpg";
import g3 from "../galery/3.jpg";
import g4 from "../galery/4.jpg";
import g5 from "../galery/5.jpg";
import g6 from "../galery/6.jpg";
import g7 from "../galery/7.jpg";
import g8 from "../galery/8.jpg";
import g9 from "../galery/9.jpg";
import g10 from "../galery/10.jpg";
import g11 from "../galery/11.jpg";
import g12 from "../galery/12.jpg";
import g13 from "../galery/13.jpg";
import g14 from "../galery/14.jpg";
import g15 from "../galery/15.jpg";
import g16 from "../galery/16.jpg";
import g17 from "../galery/17.jpg";
import g18 from "../galery/18.jpg";
import g19 from "../galery/19.jpg";
import g20 from "../galery/20.jpg";
import g21 from "../galery/21.jpg";
import g22 from "../galery/22.jpg";
import g23 from "../galery/23.jpg";
import g24 from "../galery/24.jpg";

export function Galery(props) {
  return (
    <div className="galery">
      <img src={g1} alt="" loading="lazy" />
      <img src={g2} alt="" loading="lazy" />
      <img src={g3} alt="" loading="lazy" />
      <img src={g4} alt="" loading="lazy" />
      <img src={g5} alt="" loading="lazy" />
      <img src={g6} alt="" loading="lazy" />
      <img src={g7} alt="" loading="lazy" />
      <img src={g8} alt="" loading="lazy" />
      <img src={g9} alt="" loading="lazy" />
      <img src={g10} alt="" loading="lazy" />
      <img src={g11} alt="" loading="lazy" />
      <img src={g12} alt="" loading="lazy" />
      <img src={g13} alt="" loading="lazy" />
      <img src={g14} alt="" loading="lazy" />
      <img src={g15} alt="" loading="lazy" />
      <img src={g16} alt="" loading="lazy" />
      <img src={g17} alt="" loading="lazy" />
      <img src={g18} alt="" loading="lazy" />
      <img src={g19} alt="" loading="lazy" />
      <img src={g20} alt="" loading="lazy" />
      <img src={g21} alt="" loading="lazy" />
      <img src={g22} alt="" loading="lazy" />
      <img className="half" src={g23} alt="" loading="lazy" />
      <img className="full" src={g24} alt="" loading="lazy" />
    </div>
  );
}
