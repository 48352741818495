import React, { Component } from "react";

import equipment from "../equipment.png";
import transport from "../transport.png";
import tools from "../tools.png";
import cargo from "../cargo2.jpg";

import banner from "../banner.png";

export function AboutUs(props) {
  return (
    <main id={props.id} className="about-us wrapper">
      <a href="" className="about-us__link">
        <img src={equipment} alt="equipment" />
        <span className="text">equipment</span>
      </a>

      <div className="about-us__card">
        <span className="subtitle">About Us</span>
        <h3 className="title">Humanitarian help</h3>
        <p className="text">
          As a volunteer, Mr Volodymyr Dyba coordinates the logistics of
          humanitarian aid that is coming to the city of Rzeszów at the request
          of the city authorities from 24.02.2022 and the “Blahodar” foundation.
          To find our more about his proejcts, see{" "}
          <a
            href="https://www.facebook.com/VladDondass/posts/3231641730439201"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook page
          </a>{" "}
          . Currently, Mr Dyba is engaged in his own fund, which finances
          various projects. Also organization of charity concerts and
          tournaments.{" "}
          <a
            href="https://www.facebook.com/WelcomeRzeszow"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            His foundation welcome rzeszow
          </a>{" "}
          is a co-organizer of the{" "}
          <a
            href="http://hockeybayraktar.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            international charity hockey tournament
          </a>{" "}
          on November 10-13.11.2022 in Toruń. He is actively looking for
          sponsors for his projects and regularly sends humanitarian aid to
          Ukraine.
        </p>
      </div>

      <a href="" className="about-us__link clip">
        <img src={transport} alt="transport" />
        <span className="text">transport</span>
      </a>
      <a href="" className="about-us__link link aspect-ratio-1-1">
        <img src={cargo} alt="cargo" />
        <span className="text">cargo</span>
      </a>

      <ul className="about-us__list">
        <li>Diesel generators / Heaters</li>
        <li>Kitchen appliances</li>
        <li>Powerbank from 10,000 A</li>
        <li>Products</li>
      </ul>
      <div className="about-us__banner">
        <img src={banner} alt="banner" />
        <span>Ukraine desperately needs the help of each of you!</span>
      </div>
    </main>
  );
}
