import React, { Component } from "react";

export function Main() {
  return (
    <div className="main block">
      <div className="wrapper">
        <div className="main__top-right"></div>
        <div className="main__info-block">
          <span>Make</span>
          <span>Donation</span>

          <a class="info-block__item" href="http://paypal.me/FWRzeszow" target="_blank">
            Make Donation
          </a>
        </div>
      </div>
    </div>
  );
}
