import React, { Component } from "react";

import charity from "../charity.jpg";
import paypal from "../paypal.png";

export function Charity(props) {
  return (
    <div id={props.id} className="charity wrapper">
      <h2 className="charity__title">CHARITY</h2>
      <div className="charity__col">
        <span className="charity__subtitle">
          Charitable contributions for statutory activities Non-profit
          organization "Fundacja Welcome Rzeszów"
        </span>
        <span className="charity__text">
          The organization supports <strong>people</strong> in need
        </span>
      </div>
      {CharityQR("")}
    </div>
  );
}

function CharityQR(classModal) {
  return (
    <div className={"charity__col card" + classModal}>
      <h3 className="title">Details for transferring funds</h3>
      <span className="text">
        <strong>Non-profit organization</strong> "Fundacja Welcome Rzeszów"
      </span>
      <span className="text">
        <strong>Account:</strong> Santander Bank Polska S.A SWIFT WBKPPLPP
        <br />
        IBAN Pl 73 1090 2750 0000 0001 5107 4508
      </span>
      <span className="text">
        <strong>Account: </strong> <a href="http://paypal.me/FWRzeszow" target="_blank" rel="noopener noreferrer">paypal.me</a>
      </span>
      <img src={charity} alt="charity" className="image" />
      <img src={paypal} alt="paypal" className="image" />
    </div>
  );
}
